import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const PolygrafiePage = (props: Props) => {
  return (
    <Layout>
      <h1>Polygrafie</h1>
      <SEO title="Polygrafie" />
      <p className="my-3 text-justify">
        K označování výrobků je možné používat i papírové visačky. Ty sice
        nesplňují zákonnou povinnost být po dobu životnosti výrobku trvale
        připojeny, ale nabízejí větší prostor pro uvedení podrobnějších
        informací o výrobku. Zároveň mohou být i výbornou prezentací a reklamou
        značky.
      </p>
      <p className="my-3 text-justify">
        Z technického hlediska je nabídka možnosti velice široká. Víceméně
        libovolný rozměr, barevnost, gramáž papíru, možnost výseku do atypických
        tvarů. U výseku jsou omezení - provádí se pomocí výsekového nástroje –
        vytvarované ocelové planžety, kterou není možné naohýbat do malých
        detailů. Druhým omezením výseku je cena tohoto nástroje. Většinou
        2000-3000Kč (+dph). Je na zvážení zákazníka, pro jaké množství visaček
        se výroba výsekového nástroje vyplatí. Standardně nabízíme proseknutí
        dirky do visačky, pro snadné připevnění k výrobku. Pro běžně používané
        splintovací kleště je ideální dirka 3mm, pro provázek můžeme udělat
        samozřejmě dirku větší.
      </p>
      <p className="my-3 text-justify">
        Tiskneme digitálně nebo ofsetem, naše stroje zvládnou tisk do gramáže
        400g/m2. Nejsme omezeni pouze na visačky, můžeme nabídnout jakékoli
        produkty běžné polygrafické výroby – vizitky, prospekty, letáky,
        katalogy, atd.
      </p>
      <div className="flex justify-center">
        <StaticImage
          src="../images/polygrafie/ukazky-1.png"
          alt="Syntetická kůže"
        />
      </div>
      <p className="mt-4 text-justify">
        Poptávky a jakékoli dotazy či připomínky ohledně visaček zasílejte na:{" "}
        <a className="font-bold" href="mailto:tisk@etka.cz">
          tisk@etka.cz
        </a>
      </p>
    </Layout>
  );
};

export default PolygrafiePage;
